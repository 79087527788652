@import '../../assets/mixins.less';

.lb-navbar {
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;

  .topNavContainer {
    overflow: visible;
  }

  .topNavMenuContainer {
    a {
      display: flex;
      align-items: center;
    }
    .topnav-logo {
      max-height: 2rem;
    }
  }

  & > .ui.menu {
    justify-content: space-between;
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
    min-height: unset;
    height: 100%;
    display: grid;
    grid-template-areas: 'main-menu secondary-menu';
    grid-template-columns: 1fr auto;
    background: inherit;

    .site-logo {
      .image {
        width: 2rem;
      }
    }

    .menu-main {
      grid-area: main-menu;
    }
    .menu-right {
      grid-area: secondary-menu;

      .ui.secondary.menu {
        margin: 0;
      }
    }

    .item.site-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      width: 90px;
      background: none;

      &::before {
        display: none;
      }
    }

    .menu-main {
      .ui.secondary.menu {
        display: flex;
        height: 100%;

        .item {
          font-weight: 500;
          margin: 0;
          align-self: stretch;
          border-radius: 0;
          font-size: 0.86rem;

          .item-text,
          &.dropdown .text {
            padding: 0.62em;
            color: var(--color-white);
            opacity: 0.8;
            border-radius: 4px;
            font-weight: 600;
          }
          &.dropdown {
            .icon {
              font-weight: 600;
              color: var(--color-white);
              opacity: 0.8;
              border-radius: 4px;
            }
            .menu {
              & > a.item {
                background-color: #707ad9 !important; /*Unavaoidable Default semantic styles have important in it*/
                &:hover {
                  background-color: #363f9c !important;
                }
                &.active {
                  background-color: #363f9c !important;
                }
                .item-text {
                  color: var(--color-white);
                  font-weight: 600;
                  opacity: 0.8;
                  border-radius: 4px;
                }
              }
            }
          }

          &.active:not(.dropdown) {
            background-color: var(--color-primary-background);
            .item-text {
              color: var(--color-white);
            }

            &:after {
              content: '';
              display: block;
              height: 2px;
              background-color: var(--color-white);
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-areas: 'main-menu' 'secondary-menu';
      grid-template-columns: 1fr;

      .item.site-logo {
        background-color: inherit;
      }

      .search-container {
        justify-content: flex-start;

        .search-box {
          flex-grow: 1;

          .ui.input {
            max-width: 100%;
          }
        }
      }
    }

    .ui.dropdown {
      .menu {
        right: 0;
        left: unset;
        margin-top: 8px;
      }
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .right-icon-container {
    .ant-btn.menu-icons-btn:nth-of-type(1) {
      height: unset !important;
      width: unset !important;
    }
  }
}

// Styling for the user profile avatar
.user-profile-avatar {
  .menu {
    border-radius: 4px;
    .header {
      border-bottom: 1px solid #dfe3e8;
      & > p {
        .font-bold(0.86rem, 1.5em);
        text-transform: uppercase;
        color: #7d8791;
      }
      .header-content {
        display: flex;
        align-items: center;
        div {
          .user-email {
            .font-medium(0.86rem, 1.5em);
            color: #7d8791;
            text-transform: lowercase;
          }
          .user-name {
            .font-medium(1rem, 1.28em);
            text-transform: capitalize;
            color: #1b2229;
          }
        }
      }
    }
    .item {
      font-weight: 500 !important;
      font-size: 1rem !important;
      line-height: 1.28em;
      color: #1b2229 !important;
      padding: 1.15rem 1.7rem !important;
      display: block;
      &:hover {
        background: #f5f7ff !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .lb-navbar > .ui.menu .site-logo {
    padding: 0 8px;
  }
}
