.radio-custom {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .checkbox {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid #d1d5db;
    border-radius: 50%;
    margin-right: 0.5rem;
    flex-shrink: 0;

    .selection {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-primary-light);
      margin: auto;
    }
  }

  &.active .checkbox {
    border: 1px solid var(--color-primary-light);

    .selection {
      display: block;
    }
  }

  &.disabled {
    .checkbox {
      .selection {
        background-color: #959da5;
      }
    }
    .label {
      color: #959da5;
    }
  }
}
