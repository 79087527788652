@import '../../assets/mixins.less';

.data-source-settings {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  .steps-tabs {
    position: sticky;
    top: 0rem;
    display: flex;
    flex-flow: row nowrap;
    right: 3.2rem;
    left: 9.2rem;
    padding: 1rem 0;
    z-index: 10;
    background-color: var(--color-page-bg);

    .step-tab {
      font-weight: 600;
      border-radius: 3px;
      padding: 0.72em 1.43em;
      background-color: var(--color-white);
      color: var(--color-primary-nav-text);
      border: 1px solid var(--color-primary-nav-border);

      &.active {
        color: var(--color-primary-nav-active-text);
        background-color: var(--color-primary-nav-active-bg);
        border: none;
      }
    }

    .step-tab + .step-tab {
      margin-left: 1rem;
    }

    .config-actions {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      .delete:not(.active) {
        color: #6a737d;
        border: 1px solid #6a737d;
        &:hover {
          color: #f04647;
          border: 1px solid #f04647;
        }
      }
    }
  }

  .content-wrapper {
    &.margin-top {
      margin-top: 4rem;
    }
  }

  &.edit-structured-ds {
    .content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      padding: 0;
      margin-bottom: 5rem;

      .register-form {
        flex-grow: 1;
        padding: 0 1.8rem;
        margin-bottom: 2rem;
      }
    }

    .databases-scan {
      padding-top: 2rem;
    }
  }

  .register-control {
    z-index: 2;
    padding: 3rem 1.5rem 0 1.5rem;
  }

  .data-bases-scan-container {
    flex-grow: 1;
    padding: 4rem 0 0 8rem;
  }

  .form-wrapper {
    flex-grow: 1;
    min-width: 20rem;
    margin-top: 2rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .side {
      max-width: 50vw;

      &:not(:last-of-type) {
        margin-right: 4rem;
      }
      &.right {
        min-width: 20rem;
        margin-bottom: 5rem;
      }
    }
  }

  .form-item {
    width: 26rem;

    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    &:not(:first-of-type) {
      margin-top: 1.6rem;
    }

    &.password {
      position: relative;
      .error + .toggle-password {
        background-color: #fff6f6;
      }

      &.disabled .icon.toggle-password {
        opacity: 0.45;
      }
    }

    &.calendar {
      .ui.input input {
        &::-webkit-calendar-picker-indicator {
          background: transparent;
          bottom: 0;
          color: transparent;
          cursor: pointer;
          height: auto;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
        }
      }
    }

    .toggle-password {
      position: absolute;
      top: 2.2rem;
      right: 0.7rem;
      color: var(--color-raven);
      background: var(--color-white);
      padding-left: 2px;
    }

    .ui.prompt.label {
      width: auto;
      min-width: none;
      max-width: none;
    }

    .content-margin-top {
      margin-top: 1rem;
    }

    .entity-creation-toggle {
      margin-top: 1rem;
    }

    .schedule-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .ui.fluid.dropdown.selection {
        padding: 0.6rem 1rem;
      }

      & > * {
        width: 50%;
        margin-bottom: 0 !important;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }

    &.relative {
      position: relative;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 4rem;
  }

  .label,
  .form-control .label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .popup-info-trigger {
      width: 1rem;
      margin-left: 0.3rem;
    }
  }

  .checkbox-custom {
    margin-top: 0.5rem;

    .content {
      font-size: 0.86rem;
    }
  }

  .register-control {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #e1e4e8;
    background: var(--color-white);

    &.edit-settings {
      position: absolute;
      width: 100%;
      box-shadow: 0px -4px 6px rgba(28, 30, 34, 0.06);
      border-radius: 0px 0px 8px 8px;
    }

    .divider-vertical {
      width: 1px;
      background: var(--color-datasource-form-actions-separator);
    }

    .btn-group {
      flex-grow: 1;
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      gap: var(--size-16px);

      &.center-content {
        justify-content: center;
      }

      .ui.button.cancel {
        border: none;
        margin-right: 2rem;

        &:hover {
          box-shadow: none;
          opacity: 0.7;
        }
      }
    }

    .ui.button.previous {
      background: transparent;
      display: flex;
      align-items: center;
    }
  }

  .test-connection-container {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 992px) {
  .data-source-settings {
    .register-control {
      padding: 0 8rem;
    }
  }
}
