@import '../../../../assets/mixins.less';
.ui.large.modal.modal-view.modal-delete-ds {
  max-width: 47rem;
  .header {
    padding: 1.71rem 2.28rem;
    .btn-text.close {
      position: static;
    }
    span {
      .font-semiBold(1.28rem, 1.33em);
      color: #001529;
    }
  }
  .body {
    margin-top: 0.86rem;
    padding: 0 1.14rem;
  }
  .content-delete-ds {
    .ui.message {
      background: #fff4f4;
      border-radius: 2px;
      border: 0;
      font-size: 1rem;
      box-shadow: none;
      display: flex;
      align-items: center;
    }

    .ui.message,
    .confirm-msg,
    label {
      span {
        color: #001529;
        line-height: 1.42em;
      }
    }

    .text-input {
      display: flex;
      flex-direction: column;
      input {
        .font-normal(1rem, 1.42em);
        padding: 0.72rem 0.86rem;
        box-shadow: none;
        border: 1px solid #c4c9d0;
        border-radius: 2px;
        color: #24292e;
        text-transform: uppercase;
      }
    }
  }
  .footer {
    padding-right: 32px;
    margin-top: 5.75rem;
    .btn {
      .font-medium(1rem, 1.42em);
      padding: 6px 1.14rem;
      &:hover:not(:disabled) {
        opacity: 0.85;
      }
    }
    .confirm {
      color: var(--color-white);
      &:not(:disabled),
      &:hover:not(:disabled) {
        background: #f04647 !important;
      }
    }
    .cancel {
      box-shadow: 0px 1px 0px 0px #f6f6f6;
      &,
      &:hover {
        color: #25292e !important;
        background-color: #f6f8fa !important;
      }
    }
  }
}
