@import '../assets/mixins.less';

.report .print-page {
  .xs-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.print-page.page-title,
.print-page.page-final {
  display: flex;
  flex-flow: column nowrap;
  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
  }
}
.print-page {
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  .xs-pt-1rem {
    padding-top: 1rem !important;
  }
  .xs-pt-quarter-rem {
    padding-top: 0.75rem !important;
  }
  .table-legend-text {
    .font-bold(1rem,1em);
    color: #3649c9;
  }
  .table-legend-secondary {
    .font-bold(0.86rem,1.17em);
    color: var(--color-text-secondary);
    display: flex;
    .count {
      .font-semiBold(1rem,1em);
      color: #3f4db0;
      margin-left: 0.25rem;
    }
  }
  &.attribute-page {
    padding: 2rem;
  }
  .text-tag {
    background-color: #e9edf1;
    width: 20%;
    font-size: 12px;
    padding: 5px 10px;
  }
  .bottom-summary {
    background-color: #f1f8fe;
    padding: 20px;
    font-size: 11px;
    width: 89vw;
  }
  .ui.table {
    border: 0;
    border-collapse: collapse;
    .borderless {
      background: inherit;
      tbody {
        tr {
          border-bottom: 0;
          &:nth-child(2n) {
            background-color: transparent !important;
          }
          td {
            .font-medium(0.72rem,1.75em);
          }
          td:first-child {
            color: var(--color-text-secondary);
          }
          td:last-child {
            color: var(--color-black);
          }
        }
      }
    }
    tr {
      border-bottom: 1px solid #d5dee8;
      td,
      th {
        border: 0;
        padding: 10px 18px;
      }
      &,
      th,
      td {
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
    thead {
      background-color: #3f4db0 !important;
      th {
        color: #fff !important;
        background-color: transparent;
        font-weight: 500;
        font-size: 1.15rem;
        line-height: 20px;
      }
    }

    tbody {
      tr {
        td {
          font-weight: 500;
          font-size: 1rem;
          line-height: 20px;
          color: #000000;
          &.field-name {
            color: var(--color-text-secondary) !important;
            .description-title {
              font-weight: 400;
              font-size: 0.85rem;
              line-height: 14px;
              color: #959da5;
            }

            &.title {
              font-weight: 500 !important;
              font-size: 1.1rem !important;
              color: var(--color-black) !important;
            }
          }
          &.capitalize {
            text-transform: capitalize;
          }
        }
        &:nth-child(2n) {
          background-color: #f6f8fa !important;
        }
      }
    }

    &.multi-page-table-contents {
      tbody tr {
        background-color: transparent !important;
        border: 0;
        &:last-child td {
          padding-bottom: 3rem !important;
        }
        &:first-child td {
          padding-top: 3rem !important;
        }
      }
      th {
        padding: 0px !important;
      }
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }

  .wrapper {
    .attr-sensitivity {
      padding: 2px 6px;
      font-size: 8px;
      font-weight: 600;
      line-height: 12px;
      border-radius: 2px;
      text-transform: capitalize;
      &.high {
        color: #f04647;
        background: #fff6f6;
      }
      &.low {
        background: #e3ffed;
        color: #3bb254;
      }
      &.medium {
        color: #eb6818;
        background: #fffaf1;
      }
    }
    .table-legend-light {
      font-weight: 400;
      font-size: 1.35rem;
      line-height: 1.3em;
      color: var(--color-text-secondary);
      align-self: flex-start;
      span {
        color: var(--color-emoby-clay);
        font-weight: 700;
        font-size: 0.95em;
      }
    }
    .help-box {
      align-self: flex-start;
      min-width: 100%;
      .help-legend {
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 1.7em;
        color: var(--color-river-bed);
      }
      .help-text {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.4em;
        color: var(--color-emoby-clay);
        background: #eff5ff;
        border-radius: 8px;
        p {
          max-width: 55%;
        }
      }
    }
  }

  .wrapper,
  .header {
    padding: 2rem 4rem;
  }

  .wrapper-pb-0 {
    padding-bottom: 0 !important;
  }
  .wrapper-pt-0 {
    padding-top: 0 !important;
  }
  .wrapper-cover {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    height: 7rem;
    background: linear-gradient(89.97deg, #101175 10.18%, #3633a0 48.78%, #3629ca 89.3%);
    color: var(--color-white);

    .sub-text {
      font-size: 0.72rem;
      margin-bottom: 1rem;
    }

    .text {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }

  .footer {
    display: flex;
    flex-flow: nowrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    flex-direction: column !important;

    .divider {
      margin: 1rem 0;
      height: 1px;
      width: 100%;
      background-color: #b6bcc2;
    }

    .row {
      font-size: 0.72rem;
      width: 100%;
      justify-content: space-between;
      color: var(--color-text-secondary);
    }

    &.title-styles {
      .divider {
        background-color: var(--color-white);
      }

      .row {
        color: var(--color-white);
        font-size: 0.86rem;
      }
    }
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &.with-risk-label {
      justify-content: space-between;
    }
  }

  .page-break {
    page-break-before: always;
  }

  &.page-title {
    position: relative;
    background: linear-gradient(180deg, #101175 16.44%, #3633a0 57.2%, #3629ca 100%);
    display: flex;
    .icon-box {
      img {
        width: 5rem;
      }
    }

    .logo-wrapper {
      position: absolute;
      width: 12rem;
      height: 4rem;
      z-index: 1;

      .img-container {
        width: 100%;
        height: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      .text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        font-size: 0.72rem;
        color: #bac4ff;
        border: 1px solid #5d6bce;
      }
    }

    .company-wrapper {
      padding: 1rem;
      color: #bac4ff;
      position: absolute;
      top: 9rem;
      left: 3rem;
      font-size: 0.86rem;

      .text {
        border: 1px solid #5d6bce;
        padding: 0.5rem 1rem;
      }
    }

    .main-title {
      font-size: 4.5rem;
      font-weight: 700;
      line-height: 4.5rem;
      color: var(--color-white);
      margin-top: 12rem;
    }

    .sub-title {
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 1rem;
      color: var(--color-white);
    }
    .subtitle-title {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #7b89ec;
    }
    .subtitle-value {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #ffffff;
    }
  }

  &.page-final {
    background: linear-gradient(180deg, #101175 16.44%, #3633a0 57.2%, #3629ca 100%);
    padding-top: 30rem;
    display: flex;
    justify-content: center;

    .logo-lb img {
      width: 8rem;
    }

    .final-text {
      margin-top: 1.5rem;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      color: var(--color-white);
      flex-grow: 1;
    }

    .logo-vendor,
    .final-sub-text {
      display: flex;
      justify-content: center;
    }

    .final-sub-text {
      font-size: 0.72rem;
      margin-top: 2rem;
      color: var(--color-white);
    }
  }

  .table-contents {
    display: flex;
    flex-flow: column nowrap;
  }

  .contents-item {
    color: #2b399c;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-left: 1.5rem;
    cursor: default;
  }

  .submenu-item {
    color: #2b399c;
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 2.5rem;
    cursor: default;
  }

  &.data-sources,
  &.attribute-page {
    .risky-container {
      font-weight: 400;
      font-size: 1.3rem;
      color: var(--color-text-secondary);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 2rem 0 1rem 0;

      .count {
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 700;
        color: var(--color-black);

        &.risky {
          color: var(--color-entities-risk);
        }
      }
    }
    .ds-content {
      padding: 2rem;
    }
    .ds-widgets-container {
      background: #e8eef3;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 2rem;

      .ds-header {
        margin-top: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .right {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }

        .data-source-item {
          font-weight: 600;
          font-size: 1.6rem;
          color: var(--color-black);
        }

        .risk-label {
          font-size: 1rem;
          color: var(--color-white);
          background-color: var(--color-entities-risk);
          border-radius: 4px;
          padding: 0.2rem 0.4rem;
          margin-left: 1rem;
        }

        .ds-connected {
          color: var(--color-white);
          background-color: #60b05f;
          border-radius: 4px;
          padding: 0.2rem 0.4rem;
        }
      }

      .widgets-row {
        display: grid;
        grid-template-columns: repeat(2, 49.5%);
        column-gap: 1%;
        .print-widget-ds {
          padding: 2rem;
          .item {
            font-size: 0.8rem;
          }
        }
      }

      .print-widget {
        background: var(--color-white);
        border: 1px solid #d1d5da;
        border-radius: 4px;
        padding: 1rem;
        margin-top: 0.6rem;

        &.details {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding-right: 1rem;

          .left {
            margin-right: 2rem;
          }
          .right {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            padding-top: 1rem;
          }
          .title {
            font-weight: 500;
            font-size: 0.86rem;
            color: var(--color-black);
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          .col {
            .label {
              color: var(--color-text-secondary);
              padding: 0;
              margin: 0;
            }
          }

          .count-box {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            width: 6rem;
            flex-shrink: 0;

            .icon-box {
              width: 1.3rem;
            }

            & > * {
              margin-bottom: 0.5rem;
              width: 100%;
              text-align: center;
              height: 1rem;
            }

            .count {
              margin-top: 0.25rem;
              font-weight: 600;
              font-size: 1.3rem;
              flex-grow: 1;
              height: unset;
              display: flex;
              flex-direction: column;
              align-items: center;

              .icon-box {
                height: 1rem;
              }

              .count-total-wrapper {
                bottom: -1.4rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                width: 100%;

                .count-total {
                  background-color: #e9edf1;
                  padding: 0.1rem 0.1rem;
                  font-size: 0.72rem;
                  line-height: 0.72rem;
                  font-weight: 400;
                  color: var(--color-black);
                }
              }
            }

            .text {
              font-size: 0.72rem;
              line-height: 0.8rem;
              color: var(--color-river-bed);
            }
          }

          .divider {
            width: 1px;
            background-color: #e1e4e8;
            height: 5rem;
            margin: 0 1rem;
          }
        }
      }
    }
  }

  .summary-box {
    margin-top: 2rem;
  }

  .risk-box {
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #e1e4e8;
    background: #fff;

    &.text-box {
      min-height: 5rem;
      color: #000;
      font-size: 0.86rem;
      font-weight: 600;
    }
  }

  .risk-label-box {
    text-align: center !important;

    .risk-status-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .pia-label-risk-status {
        margin: 0 !important;
        margin-top: 2px !important;
        white-space: nowrap;
      }
    }
  }

  .dfd-react-flow-renderer {
    padding: 0;
    width: 100%;

    .react-flow.graph {
      width: 840px;

      .stage {
        padding: 0.25rem 0.5rem;
        width: 110px;

        .data-source-item {
          font-size: 10px;
          font-weight: 600;

          .datasource-name {
            white-space: wrap;
            font-size: 12px;
            line-height: 12px;
          }
        }

        .text-wrapper {
          .value {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.no-data {
  flex-grow: 1;
  position: relative;
  svg {
    display: block;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-right: -50%;
  }
}
.page-overview {
  .wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  .cards-list {
    margin-top: 2rem;
    display: grid;
    grid-auto-rows: 1fr;
    gap: 1.1rem;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 0;

    .card {
      padding: 1.2rem;
      border: 1px solid #e1e4e8;
      border-radius: 4px;
      margin-right: 1.8rem;
      margin-bottom: 1.8rem;

      .card-header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 500;

        .icon-box {
          margin-right: 1rem;
          background: #1ab9ff;
          border-radius: 4px;
          width: 1.8rem;
          height: 1.8rem;

          svg {
            height: 1rem;
          }

          &.db {
            svg {
              path {
                fill: var(--color-white);
              }
            }
          }
        }
        .text {
          margin-top: -8px;
        }
        .sub-text {
          flex-basis: 100%;
          font-size: 0.8rem;
          margin-top: -10px;
          margin-left: 2.9rem;
          color: var(--color-text-secondary);
        }
      }
      .card-footer {
        background-color: #e9edf1;
        width: 50%;
        margin-left: 2.7rem;
        font-size: 10px;
        padding: 0 10px;
        margin-top: 0.5rem;
      }
      .card-content {
        display: flex;
        flex-flow: row nowrap;
        margin-left: 2.8rem;
        margin-top: 0.7rem;
        .count-item {
          .count {
            font-size: 1.2rem;
            font-weight: 600;
            color: #eb6818;

            &.total {
              color: #3f4db0;
            }
            &.opaque50 {
              opacity: 50%;
            }
          }

          .label {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
}
.page-policies.data-sources {
  .ds-name-files {
    align-items: stretch;
    flex-direction: column;
    .ds-logo-name {
      display: flex;
      margin-top: 0.5rem;
    }
  }
}

.field-name-dsr-data-type {
  color: var(--color-text-secondary);
}
.dsr-additional-comments {
  color: #959da5;
}
.dsr-data-type-bw {
  word-break: break-word;
}
.report-ropa .header {
  .text,
  .sub-text {
    color: #fff !important;
  }
}
.report-ropa {
  .print-page {
    .ui.table {
      thead th,
      tbody tr td,
      tbody tr td.field-name.title {
        font-size: 14px !important;
        font-weight: 500 !important;
      }
      .attr-sensitivity {
        font-size: 14px;
        line-height: 1.5em;
      }
      tbody tr td {
        color: #000 !important;
        word-break: break-word;
        p {
          line-height: 1.5em;
        }
      }
      thead,
      tbody {
        tr {
          background-color: transparent !important;
          td,
          th {
            padding: 1rem 1.75rem;
            line-height: 1.5em;
          }
        }
      }
      tbody tr td.field-name.title {
        color: #1b2229 !important;
      }
    }
    .table-legend-secondary {
      font-size: 14px;
      line-height: 1.4em;
      font-weight: 500;
      color: #1b2229;
      .count {
        font-weight: 700;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .header {
    .text {
      color: #fff !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
    .sub-text {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #e1e4e8 !important;
    }
  }
}
@media print {
  body,
  html,
  #app {
    width: 750px !important;
  }
  .report {
    .page,
    .print-page {
      page-break-after: always;
    }
  }
  .table {
    thead {
      background-color: #3f4db0 !important;
      th {
        color: #fff !important;
        border: none !important;
      }
    }
  }
  .truncate {
    white-space: pre-wrap !important;
    max-width: 100% !important;
  }
  .table-card-view {
    .controls {
      display: none !important;
    }
  }
  .table-legend {
    display: none !important;
  }
  .data-sources {
    .ds-widgets-container {
      break-inside: avoid;
    }
  }
  .content-wrapper {
    border: none !important;
  }
  .mobile.only {
    display: none !important;
  }
}

.xs-mb-40 {
  margin-bottom: 40px !important;
}

.xs-my-8 {
  margin: 8px !important;
}
